import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import ModeratorSelect from '../components/ModeratorSelect';
import PodcastList from '../components/PodcastList';
import PodcastDetail from '../components/PodcastDetail';
import './Discussions.css';

const Discussions = () => {
  const { user } = useAuth();
  const [selectedPodcast, setSelectedPodcast] = useState(null);

  return (
    <div className="discussions-container">
      <section className="moderators-section">
        <h2>Chat with Your Discussion Moderator</h2>
        <ModeratorSelect />
      </section>
      
      {selectedPodcast ? (
        <PodcastDetail 
          podcast={selectedPodcast} 
          onBack={() => setSelectedPodcast(null)} 
        />
      ) : (
        <section className="podcasts-section">
          <h2>Browse Podcast Discussions</h2>
          <PodcastList onSelect={setSelectedPodcast} />
        </section>
      )}
    </div>
  );
};

export default Discussions;

import React from 'react';
import './ContentPanel.css';

const ContentPanel = ({ node, position, setSelectedNode }) => {  return (
    <div 
      className="content-panel"
      style={{ 
        left: position.x + 20, 
        top: position.y - 100 
      }}
    >
      <button className="close-btn" onClick={() => setSelectedNode(null)}>×</button>
      <h3>{node.name}</h3>
      
      <section className="quotes">
        <h4>Key Insights</h4>
        {node.content.quotes.map((quote, i) => (
          <blockquote key={i}>{quote}</blockquote>
        ))}
      </section>

      <section className="guests">
        <h4>Featured Guests</h4>
        <ul>
          {node.content.guests.map((guest, i) => (
            <li key={i}>{guest}</li>
          ))}
        </ul>
      </section>

      <section className="connections">
        <h4>Connected Concepts</h4>
        <div className="connection-tags">
          {node.content.connections.map((connection, i) => (
            <span key={i} className="tag">{connection}</span>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ContentPanel;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { API_URL } from '../const';
import './ModeratorChat.css';

const ModeratorChat = () => {
  const { moderatorId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const moderators = {
    mabel: {
      name: 'Mabel Dodge Luhan',
      image: require('../images/luhan.jpg'),
      greeting: "Welcome to our discussion salon! I'm here to help you explore the deeper themes in our podcasts."
    },
    dorothy: {
      name: 'Dorothy Parker',
      image: require('../images/parker.jpg'),
      greeting: "Let's cut to the chase and discuss these podcasts with the wit and insight they deserve."
    }
  };

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [episodeContext, setEpisodeContext] = useState(null);
  const [showEpisodeSelect, setShowEpisodeSelect] = useState(true);
  const [podcasts, setPodcasts] = useState([]);
  useEffect(() => {
    // Fetch available podcasts
    fetch(`${API_URL}/api/podcasts`, {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    })
    .then(res => res.json())
    .then(data => setPodcasts(data));
  }, []);

  useEffect(() => {
    if (moderators[moderatorId]) {
      setMessages([{ 
        content: moderators[moderatorId].greeting,
        sender: 'moderator'
      }]);
    }
  }, [moderatorId]);

  const handleEpisodeSelect = (selection) => {
    setEpisodeContext(selection);
    setShowEpisodeSelect(false);
    
    // Initial greeting with selected context
    const greeting = selection.specific 
      ? `Welcome! Let's discuss the episode with ${selection.guest}. What aspects would you like to explore?`
      : `Welcome! Let's explore themes across the podcast episodes. What topics interest you most?`;
      
    setMessages([{
      content: greeting,
      sender: 'moderator'
    }]);
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    
    setIsLoading(true);
    setMessages(prev => [...prev, { content: newMessage, sender: 'user' }]);
    setNewMessage('');

    try {
      const response = await fetch(`${API_URL}/api/moderators/${moderatorId}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token')
        },
        body: JSON.stringify({
          content: newMessage,
          context: episodeContext
        })
      });

      const data = await response.json();
      setMessages(prev => [...prev, { 
        content: data.response, 
        sender: 'moderator'
      }]);
    } catch (error) {
      console.error('Error:', error);
    }
    
    setIsLoading(false);
  };
return (
  <div className="moderator-container">
    {showEpisodeSelect ? (
      <div className="episode-select-modal">
        <h2>What would you like to discuss?</h2>
        <div className="episode-options">
          <button onClick={() => handleEpisodeSelect({ type: 'general', specific: false })}>
            General Themes Across Episodes
          </button>
          <div className="specific-episodes">
            <h3>Or select a specific episode:</h3>
            {Array.isArray(podcasts) && podcasts.map(podcast => (
              <button
                key={podcast._id}
                onClick={() => handleEpisodeSelect({
                  type: 'specific',
                  specific: true,
                  episodeId: podcast._id,
                  guest: podcast.guest,
                  title: podcast.title
                })}
              >
                {podcast.guest} - {podcast.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    ) : (
      <div className="moderator-chat">
        <div className="chat-header">
          <div className="header-left">
            <img src={moderators[moderatorId].image} alt={moderators[moderatorId].name} />
            <h2>{moderators[moderatorId].name}</h2>
          </div>
          <button className="close-chat" onClick={() => navigate(-1)}>← Back</button>
        </div>
        <div className="chat-messages">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              {message.content}
            </div>
          ))}
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <button onClick={handleSendMessage} disabled={isLoading || !newMessage.trim()}>
            Send
          </button>
        </div>
      </div>
    )}
  </div>
);

}

export default ModeratorChat;
import React from 'react';

const SavedChats = () => {
  return (
    <div className="saved-chats-container">
      <h1>Your Saved Chats</h1>
    </div>
  );
};

export default SavedChats;

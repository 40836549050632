import React, { useState, useEffect } from 'react';
import { API_URL } from '../const';
import { useAuth } from '../context/AuthContext';
import './PodcastDetail.css';
import ModeratorHelper from './ModeratorHelper';
import Analysis from './Analysis';

const PodcastDetail = ({ podcast, onBack }) => {
  const [discussion, setDiscussion] = useState(null);
  const [showFullSummary, setShowFullSummary] = useState(false);
  const [isPostBoxExpanded, setIsPostBoxExpanded] = useState(false);
  const [postContent, setPostContent] = useState('');
  const { user } = useAuth();
  const [showModeratorHelper, setShowModeratorHelper] = useState(false);
  const [perspectiveSummaries, setPerspectiveSummaries] = useState([]);
  const [creatorFeedback, setCreatorFeedback] = useState([]);
  const [rating, setRating] = useState(0);
  const [feedbackContent, setFeedbackContent] = useState('');
  const [perspectives, setPerspectives] = useState([]);

  const fetchDiscussion = async () => {
    const response = await fetch(`${API_URL}/api/discussions/${podcast._id}`, {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    const data = await response.json();
    setDiscussion(data);
  };

  useEffect(() => {
    const loadData = async () => {
      await fetchDiscussion();
      fetchPerspectives();
    };
    loadData();
  }, [podcast._id]);

  const handlePostSubmit = async () => {
    try {
      const response = await fetch(`${API_URL}/api/discussions/${podcast._id}/posts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token')
        },
        body: JSON.stringify({
          content: postContent,
          moderator: discussion?.moderator
        })
      });
      const data = await response.json();
      setPostContent('');
      setIsPostBoxExpanded(false);
      fetchDiscussion();
    } catch (error) {
      console.error('Error posting:', error);
    }
  };
  const fetchPerspectives = async () => {
    try {
      const posts = discussion?.posts || [];
      
      if (posts.length < 2) {
        setPerspectives([{
          summary: "Join the conversation! Share your thoughts on this episode.",
          citedUsers: []
        }]);
        return;
      }
      
      const response = await fetch(`${API_URL}/api/discussions/${podcast._id}/summary`, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      
      const data = await response.json();
      const perspectives = [{
        summary: data.postsSummary,
        citedUsers: posts.slice(0, 3).map(post => ({
          comment: post.content,
          username: post.userId?.username || 'Community Member'
        }))
      }];
      
      setPerspectives(perspectives);
    } catch (error) {
      console.error('Error generating perspectives:', error);
    }
  };
  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/discussions/${podcast._id}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token')
        },
        body: JSON.stringify({
          rating,
          content: feedbackContent,
          userId: user._id
        })
      });
      
      const data = await response.json();
      setCreatorFeedback([...creatorFeedback, data]);
      setRating(0);
      setFeedbackContent('');
      
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div className="podcast-detail">
      <button className="back-button" onClick={onBack}>
        ← Back to Podcasts
      </button>

      <header>
        <h2>{podcast.title}</h2>
        <div className="podcast-meta">
          <span>Guest: {podcast.guest}</span>
          <span>Date: {new Date(podcast.date).toLocaleDateString()}</span>
        </div>
        {podcast.summary && (
          <div className="podcast-summary">
            <h3>Summary</h3>
            <p>
              {showFullSummary ? podcast.summary : `${podcast.summary.slice(0, 200)}...`}
              <button
                className="toggle-summary"
                onClick={() => setShowFullSummary(!showFullSummary)}
              >
                {showFullSummary ? 'Show Less' : 'Show More'}
              </button>
            </p>
          </div>
        )}

        {/* Add Analysis component here */}
        <div className="podcast-analysis">
          <Analysis podcastId={podcast._id} />
        </div>

        <div className="podcast-links">
          <a href={podcast.youtubeUrl} target="_blank" rel="noopener noreferrer">Watch on YouTube</a>
          <a href={podcast.spotifyUrl} target="_blank" rel="noopener noreferrer">Listen on Spotify</a>
          <a href={podcast.transcriptUrl} target="_blank" rel="noopener noreferrer">Read Transcript</a>
        </div>
      </header>

      <div className="discussion-container">
      <section className="perspective-summaries">
  <h3>Community Perspectives</h3>
  {perspectives.map((perspective, index) => (
  <div key={index} className={`perspective-summary ${discussion?.posts?.length < 2 ? 'empty-state' : 'active'}`}>
    <p>{perspective.summary}</p>
    {perspective.citedUsers.length > 0 && (
      <div className="cited-users">
        {perspective.citedUsers.map((citation, idx) => (
          <blockquote key={idx}>
            <p>{citation.comment}</p>
            <footer>- {citation.username}</footer>
          </blockquote>
        ))}
      </div>
    )}
  </div>
))}
  {perspectives.length === 0 && (
    <div className="no-perspectives">
      <p>Be the first to share your perspective on this episode!</p>
    </div>
  )}
</section>

        <section className="discussion-section">
          <div className={`post-box ${isPostBoxExpanded ? 'expanded' : ''}`}>
            {!isPostBoxExpanded ? (
              <div className="post-box-trigger" onClick={() => setIsPostBoxExpanded(true)}>
                <img src={user?.profilePicture || '/default-avatar.png'} alt="Profile" />
                <span>Start a discussion about this episode...</span>
              </div>
            ) : (
              <div className="post-box-expanded">
                <textarea
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  placeholder="What are your thoughts on this episode?"
                  autoFocus
                />
                <div className="post-box-actions">
                  <button
                    className="moderator-help-btn"
                    onClick={() => setShowModeratorHelper(true)}
                  >
                    Get help from {discussion?.moderator || 'moderator'}
                  </button>
                  {showModeratorHelper && (
                    <ModeratorHelper
                      moderatorId={discussion?.moderator}
                      currentContent={postContent}
                      onUpdate={setPostContent}
                      onClose={() => setShowModeratorHelper(false)}
                      podcast={podcast}
                    />
                  )}
                  <div className="post-buttons">
                    <button
                      className="cancel-btn"
                      onClick={() => {
                        setIsPostBoxExpanded(false);
                        setPostContent('');
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="post-btn"
                      disabled={!postContent.trim()}
                      onClick={handlePostSubmit}
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {discussion?.posts.map(post => (
            <div key={post._id} className="post">
              <div className="post-header">
                <img src={post.userId?.profilePicture || '/default-avatar.png'} alt="Profile" />
                <div className="post-meta">
                  <span className="post-author">{post.userId?.username}</span>
                  <span className="post-time">{new Date(post.createdAt).toLocaleDateString()}</span>
                </div>
              </div>
              <div className="post-content">
                <p>{post.content}</p>
              </div>
              <div className="post-engagement">
                <button className="like-btn">Like</button>
                <button className="comment-btn">Comment</button>
              </div>
            </div>
          ))}
        </section>

        <section className="creator-feedback">
        <h3>Feedback for Creators</h3>
  <form className="feedback-form" onSubmit={handleFeedbackSubmit}>
    <div className="rating-container">
      <label>Episode Rating</label>
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            type="button"
            className={`star ${star <= rating ? 'active' : ''}`}
            onClick={() => setRating(star)}
          >
            ★
          </button>
        ))}
      </div>
    </div>
    <textarea
      placeholder="Share constructive feedback for the podcast creators..."
      value={feedbackContent}
      onChange={(e) => setFeedbackContent(e.target.value)}
    />
    <button 
      type="submit"
      className="submit-feedback"
      disabled={!rating || !feedbackContent.trim()}
    >
      Submit Feedback
    </button>
  </form>

  <div className="feedback-list">
    {creatorFeedback.map((feedback, index) => (
      <div key={index} className="feedback-item">
        <div className="feedback-header">
          <div className="feedback-stars">
            {[...Array(feedback.rating)].map((_, i) => (
              <span key={i} className="star active">★</span>
            ))}
          </div>
          <span className="feedback-date">
            {new Date(feedback.createdAt).toLocaleDateString()}
          </span>
        </div>
        <p className="feedback-content">{feedback.content}</p>
      </div>
    ))}
  </div>
</section>
      </div>
    </div>
  );
};

export default PodcastDetail;
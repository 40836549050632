import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { API_URL } from '../const';
import './PodcastUpload.css';

const PodcastUpload = () => {
  const { token } = useAuth();
  const [formData, setFormData] = useState({
    title: '',
    host: '',
    guest: '',
    description: '',
    youtubeUrl: '',
    spotifyUrl: '',
    date: '',
    tags: ''
  });
  const [transcript, setTranscript] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted with file:', transcript);
    const data = new FormData();
    
    Object.keys(formData).forEach(key => {
      data.append(key, formData[key]);
      console.log('Adding form field:', key, formData[key]);
    });
    
    data.append('transcript', transcript);
    console.log('FormData created:', data);

    const response = await fetch(`${API_URL}/api/podcasts/upload`, {
      method: 'POST',
      headers: {
        'x-auth-token': token
      },
      body: data
    });
    
    const result = await response.json();
    console.log('Upload result:', result);
  };

  return (
    <div className="upload-container">
      <h2>Upload Podcast Transcript</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Title"
          onChange={e => setFormData({...formData, title: e.target.value})}
        />
        <input
          type="text"
          placeholder="Host"
          onChange={e => setFormData({...formData, host: e.target.value})}
        />
        <input
          type="text"
          placeholder="Guest"
          onChange={e => setFormData({...formData, guest: e.target.value})}
        />
        <textarea
          placeholder="Description"
          onChange={e => setFormData({...formData, description: e.target.value})}
        />
        <input
          type="text"
          placeholder="YouTube URL"
          onChange={e => setFormData({...formData, youtubeUrl: e.target.value})}
        />
        <input
          type="text"
          placeholder="Spotify URL"
          onChange={e => setFormData({...formData, spotifyUrl: e.target.value})}
        />
        <input
          type="date"
          onChange={e => setFormData({...formData, date: e.target.value})}
        />
        <input
          type="text"
          placeholder="Tags (comma separated)"
          onChange={e => setFormData({...formData, tags: e.target.value})}
        />
        <input
          type="file"
          accept=".txt"
          onChange={e => setTranscript(e.target.files[0])}
        />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

export default PodcastUpload;

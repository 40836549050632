import React, { useState, useEffect } from 'react';
import './Analysis.css';

const Analysis = ({ podcastId }) => {
  const [analysis, setAnalysis] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    episodeContent: false,
    thematicConnections: false,
    guestPerspective: false
  });

  useEffect(() => {
    const fetchAnalysis = async () => {
      const token = localStorage.getItem('token');
      const baseUrl = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:8080' 
        : 'https://historical-chat-246901492539.us-central1.run.app';

      const response = await fetch(`${baseUrl}/api/threads/keyEpisode/${podcastId}/analysis`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        }
      });
      const data = await response.json();
      setAnalysis(data.analysis);
    };

    fetchAnalysis();
  }, [podcastId]);

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <div className="analysis-section">
      <h3>Episode Analysis</h3>
      {analysis && (
        <div className="analysis-content">
          <div className="analysis-group">
            <button 
              className="section-toggle"
              onClick={() => toggleSection('episodeContent')}
            >
              {expandedSections.episodeContent ? 'Hide' : 'Show'} Core Ideas Analysis
            </button>
            {expandedSections.episodeContent && (
              <div className="section-content">
                <div className="analysis-item">
                  <h5>Core Ideas</h5>
                  <p>{analysis.episodeContent.coreIdeas}</p>
                  <h5>Philosophical Frameworks</h5>
                  <p>{analysis.episodeContent.philosophicalFrameworks}</p>
                  <h5>Technical Concepts</h5>
                  <p>{analysis.episodeContent.technicalConcepts}</p>
                  <h5>Emotional Elements</h5>
                  <p>{analysis.episodeContent.emotionalElements}</p>
                </div>
              </div>
            )}
          </div>

          <div className="analysis-group">
            <button 
              className="section-toggle"
              onClick={() => toggleSection('thematicConnections')}
            >
              {expandedSections.thematicConnections ? 'Hide' : 'Show'} Thematic Connections
            </button>
            {expandedSections.thematicConnections && (
              <div className="section-content">
                <div className="analysis-item">
                  <h5>Cross-Domain Themes</h5>
                  <p>{analysis.thematicConnections.crossDomainThemes}</p>
                  <h5>Fundamental Questions</h5>
                  <p>{analysis.thematicConnections.fundamentalQuestions}</p>
                  <h5>Contemporary Challenges</h5>
                  <p>{analysis.thematicConnections.contemporaryChallenges}</p>
                  <h5>Universal Experiences</h5>
                  <p>{analysis.thematicConnections.universalExperiences}</p>
                </div>
              </div>
            )}
          </div>

          <div className="analysis-group">
            <button 
              className="section-toggle"
              onClick={() => toggleSection('guestPerspective')}
            >
              {expandedSections.guestPerspective ? 'Hide' : 'Show'} Guest Perspective
            </button>
            {expandedSections.guestPerspective && (
              <div className="section-content">
                <div className="analysis-item">
                  <h5>Expertise</h5>
                  <p>{analysis.guestPerspective.expertise}</p>
                  <h5>Philosophical Framework</h5>
                  <p>{analysis.guestPerspective.philosophicalFramework}</p>
                  <h5>Approach Style</h5>
                  <p>{analysis.guestPerspective.approachStyle}</p>
                  <h5>Contributions</h5>
                  <p>{analysis.guestPerspective.contributions}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Analysis;
import React, { useState, useEffect } from 'react';
import { API_URL } from '../const';
import SearchBar from './SearchBar';
import PodcastDetail from './PodcastDetail';
import './PodcastList.css';

const PodcastList = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPodcast, setSelectedPodcast] = useState(null);

  useEffect(() => {
    fetchPodcasts();
  }, []);

  const fetchPodcasts = async () => {
    try {
      const response = await fetch(`${API_URL}/api/podcasts`, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      const data = await response.json();
      setPodcasts(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching podcasts:', error);
      setLoading(false);
    }
  };

  const handleSearch = async (query) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/api/podcasts/search?query=${query}`, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      const data = await response.json();
      setPodcasts(data);
      setLoading(false);
    } catch (error) {
      console.error('Error searching podcasts:', error);
      setLoading(false);
    }
  };

  return (
    <div className="podcast-list-container">
      {selectedPodcast ? (
        <PodcastDetail 
          podcast={selectedPodcast}
          onBack={() => setSelectedPodcast(null)}
        />
      ) : (
        <>
          <div className="podcast-list-header">
            <SearchBar onSearch={handleSearch} />
          </div>
          <div className="podcast-list">
            {loading ? (
              <div className="loading">Loading podcasts...</div>
            ) : (
              podcasts.map(podcast => (
                <div
                  key={podcast._id}
                  className="podcast-card"
                  onClick={() => setSelectedPodcast(podcast)}
                >
                  <h3>{podcast.title}</h3>
                  <div className="podcast-meta">
                    <span>Host: {podcast.host}</span>
                    <span>Guest: {podcast.guest}</span>
                  </div>
                  <p>{podcast.description}</p>
                  <div className="podcast-tags">
                    {podcast.tags.map(tag => (
                      <span key={tag} className="tag">{tag}</span>
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PodcastList;
import React, { createContext, useState, useContext, useEffect } from 'react';
//import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { API_URL } from '../const';
//import { auth } from '../config/firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));

  // Existing JWT login
  const login = (userData, authToken) => {
    setUser(userData);
    setToken(authToken);
    localStorage.setItem('token', authToken);
  };

  // Add Firebase login
  const firebaseLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      const firebaseToken = await result.user.getIdToken();
      
      // Sync with our backend
      const response = await fetch(`${API_URL}/api/auth/firebase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${firebaseToken}`
        }
      });
      
      const data = await response.json();
      login(data.user, data.token);
    } catch (error) {
      console.error('Firebase auth error:', error);
      throw error;
    }
  };

  const value = {
    user,
    token,
    login,
    firebaseLogin,
    register: async (email, password) => {
      const response = await fetch(`${API_URL}/api/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });
      
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      
      login(data.user, data.token);
    },
    logout: () => {
      setUser(null);
      setToken(null);
      localStorage.clear();
      firebaseAuth.signOut();
      window.location.reload();
    }
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ModeratorSelect.css';

const ModeratorSelect = () => {
  const navigate = useNavigate();
  const moderators = [
    {
      id: 'mabel',
      name: 'Mabel Dodge Luhan',
      image: require('../images/luhan.jpg'),
      description: 'Art patron and social activist'
    },
    {
      id: 'dorothy',
      name: 'Dorothy Parker',
      image: require('../images/parker.jpg'),
      description: 'Writer and wit extraordinaire'
    }
  ];

  const handleModeratorSelect = (moderatorId) => {
    navigate(`/chat/${moderatorId}`);
  };

  return (
    <div className="moderator-grid">
      {moderators.map(moderator => (
        <div 
          key={moderator.id} 
          className="moderator-card"
          onClick={() => handleModeratorSelect(moderator.id)}
        >
          <img src={moderator.image} alt={moderator.name} />
          <h3>{moderator.name}</h3>
          <p>{moderator.description}</p>
        </div>
      ))}
    </div>
  );
};

export default ModeratorSelect;

import React, { useState } from 'react';
import ChatInterface from './ChatInterface';
import { API_URL } from '../const';
import './ModeratorHelper.css';

const ModeratorHelper = ({ moderatorId, currentContent, onUpdate, onClose, podcast }) => {
  const [showChat, setShowChat] = useState(false);

  const handleStartChat = () => {
    const character = {
      id: moderatorId,
      name: moderatorId === 'mabel' ? 'Mabel Dodge Luhan' : 'Dorothy Parker',
      context: {
        type: 'post_crafting',
        currentContent: currentContent,
        episode: {
          title: podcast.title,
          guest: podcast.guest,
          summary: podcast.summary
        }
      }
    };
    setShowChat(true);
  };

  return (
    <div className="moderator-helper-modal">
      {!showChat ? (
        <div className="helper-content">
          <h3>Get help from {moderatorId === 'mabel' ? 'Mabel' : 'Dorothy'}</h3>
          <p>Start a conversation about your thoughts on {podcast.title} with {podcast.guest}</p>
          <div className="helper-actions">
            <button onClick={handleStartChat}>Start Chat</button>
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
      ) : (
        <ChatInterface 
          character={{
            id: moderatorId,
            name: moderatorId === 'mabel' ? 'Mabel Dodge Luhan' : 'Dorothy Parker'
          }}
          context={{
            type: 'post_crafting',
            currentContent: currentContent,
            episode: {
              title: podcast.title,
              guest: podcast.guest,
              summary: podcast.summary
            }
          }}
          onClose={onClose}
          onPostUpdate={onUpdate}
        />
      )}
    </div>
  );
};
export default ModeratorHelper;
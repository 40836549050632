import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navigation/Navbar';
import Login from './components/Login';
import CharacterSelect from './components/CharacterSelect';
import SavedChats from './pages/SavedChats';
import Discussions from './pages/Discussions';
import ChatFeed from './components/ChatFeed';
import ChatInterface from './components/ChatInterface';
import PodcastUpload from './components/PodcastUpload';
import ModeratorChat from './components/ModeratorChat';
import Register from './components/auth/Register';
import ThreadMap from './components/ThreadMap';
import './App.css';

function App() {
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [isMultiChatActive, setIsMultiChatActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentView, setCurrentView] = useState('characters');

  const handleCharacterSelect = (character) => {
    if (isMultiChatActive) {
      if (selectedCharacters.find(c => c.id === character.id)) {
        setSelectedCharacters(selectedCharacters.filter(c => c.id !== character.id));
      } else if (selectedCharacters.length < 4) {
        setSelectedCharacters([...selectedCharacters, character]);
      }
    } else {
      setSelectedCharacter(character);
    }
  };

  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={
              <>
                <CharacterSelect
                  onSelectCharacter={handleCharacterSelect}
                  selectedCharacters={selectedCharacters}
                  searchQuery={searchQuery}
                />
                {selectedCharacter && (
                  <ChatInterface
                    character={selectedCharacter}
                    onClose={() => setSelectedCharacter(null)}
                  />
                )}
              </>
            } />
            <Route path="/characters" element={
              <>
                <CharacterSelect
                  onSelectCharacter={handleCharacterSelect}
                  selectedCharacters={selectedCharacters}
                  searchQuery={searchQuery}
                />
                {selectedCharacter && (
                  <ChatInterface
                    character={selectedCharacter}
                    onClose={() => setSelectedCharacter(null)}
                  />
                )}
              </>
            } />
            <Route path="/threads" element={
              <PrivateRoute>
                <ThreadMap />
              </PrivateRoute>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/saved" element={<SavedChats />} />
            <Route path="/discussions" element={<Discussions />} />
            <Route path="/upload" element={<PodcastUpload />} />
            <Route path="/chat/:moderatorId" element={<ModeratorChat />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;


import React, { useState, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import { useSpring, animated } from 'react-spring';
import ContentPanel from './ContentPanel';
import './ThreadMap.css';

const getThemeColor = (theme) => {
  const themeColors = {
    philosophy: '#7209b7',
    history: '#3a0ca3',
    science: '#4361ee',
    technology: '#4cc9f0',
    economics: '#f72585',
    politics: '#7b2cbf'
  };
  return themeColors[theme] || '#4361ee';
};

const ThreadMap = () => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const fadeIn = useSpring({
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 280, friction: 20 }
  });

  const mockGraphData = {
    nodes: [
      { 
        id: 1, 
        name: "Consciousness & AI",
        val: 30,
        color: getThemeColor('philosophy'),
        theme: 'philosophy',
        content: {
          quotes: ["The hard problem of consciousness remains one of our greatest mysteries"],
          guests: ["David Chalmers", "Max Tegmark"],
          connections: ["Free Will", "Neural Networks", "Emergence"]
        }
      },
      { 
        id: 2, 
        name: "Neural Networks",
        val: 25,
        color: getThemeColor('science'),
        theme: 'science',
        content: {
          quotes: ["Deep learning systems exhibit unexpected emergent properties"],
          guests: ["Yann LeCun", "Demis Hassabis"],
          connections: ["AI Safety", "Consciousness", "Complex Systems"]
        }
      },
      { 
        id: 3, 
        name: "Evolution of Mind",
        val: 28,
        color: getThemeColor('history'),
        theme: 'history',
        content: {
          quotes: ["The development of intelligence follows fascinating patterns"],
          guests: ["Steven Pinker", "Daniel Dennett"],
          connections: ["Language", "Consciousness", "Cultural Evolution"]
        }
      },
      { 
        id: 4, 
        name: "AI Safety & Ethics",
        val: 25,
        color: getThemeColor('technology'),
        theme: 'technology',
        content: {
          quotes: ["The alignment problem is central to developing beneficial AI"],
          guests: ["Stuart Russell", "Nick Bostrom"],
          connections: ["Neural Networks", "Philosophy of Mind", "Future of AI"]
        }
      },
      { 
        id: 5, 
        name: "Language & Thought",
        val: 27,
        color: getThemeColor('philosophy'),
        theme: 'philosophy',
        content: {
          quotes: ["Language shapes how we think and perceive reality"],
          guests: ["Noam Chomsky", "Steven Pinker"],
          connections: ["Evolution of Mind", "AI", "Cultural Development"]
        }
      }
    ],
    links: [
      { source: 1, target: 2, value: 5 },
      { source: 2, target: 3, value: 3 },
      { source: 1, target: 3, value: 4 },
      { source: 2, target: 4, value: 4 },
      { source: 3, target: 4, value: 5 },
      { source: 5, target: 1, value: 4 },  
      { source: 5, target: 3, value: 5 },  
      { source: 5, target: 4, value: 3 }   
    ]
  };
  return (
    <div className="thread-map-container">
      <animated.div style={fadeIn}>
        <ForceGraph2D
          graphData={mockGraphData}
          nodeLabel="name"
          nodeColor="color"
          nodeVal="val"
          linkWidth={2}
          linkColor={() => '#4361ee'}
          linkOpacity={0.8}
          d3Force={{
            charge: {
              strength: -2000,  // More repulsion between nodes
              distanceMax: 500  // Maximum distance for force effect
            },
            link: {
              distance: 200,    // Longer default link length
              strength: 0.8     // Slightly relaxed link strength
            }
          }}
          linkDirectionalParticles={2}
          linkDirectionalParticleSpeed={0.005}
          onNodeClick={(node, event) => {
            setSelectedNode(node);
            setTooltipPosition({ x: event.pageX, y: event.pageY });
          }}
        />
        {selectedNode && (
          <ContentPanel 
            node={selectedNode}
            position={tooltipPosition}
            setSelectedNode={setSelectedNode}
          />
        )}
      </animated.div>
    </div>
  );
};
export default ThreadMap;

import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { API_URL } from '../const';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, firebaseLogin } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('1. Form submitted');
    console.log('2. API URL:', API_URL);
    console.log('3. Making fetch request to:', `${API_URL}/api/auth/login`);
    
    const response = await fetch(`${API_URL}/api/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    console.log('4. Response received:', response);
    
    const data = await response.json();
    console.log('5. Parsed data:', data);
    
    login(data.user, data.token);
    console.log('6. Login complete');
  };

  const handleGoogleLogin = async () => {
    try {
      await firebaseLogin();
      // Redirect to threads page on success
      navigate('/threads');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit">Login</button>
      </form>
      
      <div className="auth-divider">or</div>
      
      <button 
        className="google-login-btn"
        onClick={handleGoogleLogin}
      >
        Continue with Google
      </button>
    </div>
  );
};

export default Login;
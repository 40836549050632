import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './Navbar.css';

const Navbar = () => {
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const navRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!touchStart) return;
    
    const touchDown = e.touches[0].clientY;
    const diff = touchStart - touchDown;

    if (diff > 50) setIsMenuOpen(false);
    if (diff < -50) setIsMenuOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <nav 
      className="navbar" 
      ref={navRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className="nav-brand">
        <Link to="/">Historical Chat</Link>
        <button className="menu-toggle" onClick={toggleMenu}>
          <span className={`hamburger ${isMenuOpen ? 'active' : ''}`}></span>
        </button>
      </div>
      <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        {user ? (
          <>
            <Link to="/characters" onClick={() => setIsMenuOpen(false)}>Characters</Link>
            <Link to="/saved" onClick={() => setIsMenuOpen(false)}>Saved Chats</Link>
            <Link to="/discussions" onClick={() => setIsMenuOpen(false)}>Discussions</Link>
            <Link to="/threads" onClick={() => setIsMenuOpen(false)}>Thread Map</Link>
            <Link to="/upload" onClick={() => setIsMenuOpen(false)}>Upload</Link>
            <button onClick={logout}>Logout</button>
          </>
        ) : (
          <>
            <Link to="/login" onClick={() => setIsMenuOpen(false)}>Login</Link>
            <Link to="/register" onClick={() => setIsMenuOpen(false)}>Register</Link>
            <Link to="/" onClick={() => setIsMenuOpen(false)}>Characters</Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;